export default {
  'pages.layouts.userLayout.title':
    'Ant Design is the most influential web design specification in Xihu district',
  'pages.login.accountLogin.tab': 'Account Login',
  'pages.login.accountLogin.errorMessage': 'Incorrect username/password(admin/ant.design)',
  'pages.login.failure': 'Login failed, please try again!',
  'pages.login.success': 'Login successful!',
  'pages.login.username.placeholder': 'Username or email',
  'pages.login.username.required': 'Please input your username!',
  'pages.login.password.placeholder': 'Password',
  'pages.login.password.required': 'Please input your password!',
  'pages.login.phoneLogin.tab': 'Phone Login',
  'pages.login.phoneLogin.errorMessage': 'Verification Code Error',
  'pages.login.phoneNumber.placeholder': 'Phone Number',
  'pages.login.phoneNumber.required': 'Please input your phone number!',
  'pages.login.phoneNumber.invalid': 'Phone number is invalid!',
  'pages.login.captcha.placeholder': 'Verification Code',
  'pages.login.captcha.required': 'Please input verification code!',
  'pages.login.phoneLogin.getVerificationCode': 'Get Code',
  'pages.getCaptchaSecondText': 'sec(s)',
  'pages.login.rememberMe': 'Remember me',
  'pages.login.forgotPassword': 'Forgot Password ?',
  'pages.login.submit': 'Login',
  'pages.login.loginWith': 'Login with :',
  'pages.login.registerAccount': 'Register Account',
  'pages.welcome.advancedComponent': 'Advanced Component',
  'pages.welcome.link': 'Welcome',
  'pages.welcome.advancedLayout': 'Advanced Layout',
  'pages.welcome.alertMessage': 'Faster and stronger heavy-duty components have been released.',
  'pages.admin.subPage.title': 'This page can only be viewed by Admin',
  'pages.admin.subPage.alertMessage':
    'Umi ui is now released, welcome to use npm run ui to start the experience.',
  'pages.searchTable.createForm.newRule': 'New Rule',
  'pages.searchTable.updateForm.ruleConfig': 'Rule configuration',
  'pages.searchTable.updateForm.basicConfig': 'Basic Information',
  'pages.searchTable.updateForm.ruleName.nameLabel': 'Rule Name',
  'pages.searchTable.updateForm.ruleName.nameRules': 'Please enter the rule name!',
  'pages.searchTable.updateForm.ruleDesc.descLabel': 'Rule Description',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder': 'Please enter at least five characters',
  'pages.searchTable.updateForm.ruleDesc.descRules':
    'Please enter a rule description of at least five characters!',
  'pages.searchTable.updateForm.ruleProps.title': 'Configure Properties',
  'pages.searchTable.updateForm.object': 'Monitoring Object',
  'pages.searchTable.updateForm.ruleProps.templateLabel': 'Rule Template',
  'pages.searchTable.updateForm.ruleProps.typeLabel': 'Rule Type',
  'pages.searchTable.updateForm.schedulingPeriod.title': 'Set Scheduling Period',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': 'Starting Time',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules': 'Please choose a start time!',
  'pages.searchTable.titleDesc': 'Description',
  'pages.searchTable.ruleName': 'Rule name is required',
  'pages.searchTable.titleCallNo': 'Number of Service Calls',
  'pages.searchTable.titleStatus': 'Status',
  'pages.searchTable.nameStatus.default': 'default',
  'pages.searchTable.nameStatus.running': 'running',
  'pages.searchTable.nameStatus.online': 'online',
  'pages.searchTable.nameStatus.abnormal': 'abnormal',
  'pages.searchTable.titleUpdatedAt': 'Last Scheduled at',
  'pages.searchTable.exception': 'Please enter the reason for the exception!',
  'pages.searchTable.titleOption': 'Option',
  'pages.searchTable.config': 'Configuration',
  'pages.searchTable.subscribeAlert': 'Subscribe to alerts',
  'pages.searchTable.title': 'Promo Usage Log',
  'pages.searchTable.new': 'New',
  'pages.searchTable.chosen': 'chosen',
  'pages.searchTable.item': 'item',
  'pages.searchTable.totalServiceCalls': 'Total Number of Service Calls',
  'pages.searchTable.tenThousand': '0000',
  'pages.searchTable.batchDeletion': 'bacth deletion',
  'pages.searchTable.batchApproval': 'batch approval',
  'pages.Promotion.form.title': 'Promotion Form',
  'pages.Promotion.createForm.new': 'Create Voucher/Coupon',
  'pages.Promotion.updateForm.order.placeholder': 'Please enter the Order Type!',
  'pages.Promotion.order.required': 'The Order Type is required',
  'pages.Promotion.updateForm.offerType.placeholder': 'Please enter the Offer Type!',
  'pages.Promotion.offerType.required': 'The Offer Type is required',
  'pages.Promotion.purchased.required': 'The minimum renew cycles is required',
  'pages.Promotion.updateForm.purchased.placeholder': 'Minimum Renew cycle',
  'pages.Promotion.code.required': 'The Code is required',
  'pages.Promotion.updateForm.code.label': 'Promo Code',
  'pages.Promotion.updateForm.code.placeholder': 'Please enter the Code!',
  'pages.Promotion.merchant_code.required': 'The Merchant is required',
  'pages.Promotion.application.required': 'The Application is required',
  'pages.Promotion.updateForm.merchant_code.placeholder': 'Please enter the Merchant!',
  'pages.Promotion.updateForm.application.placeholder': 'Please enter the Application!',
  'pages.Promotion.mode.required': 'The Mode is required',
  'pages.Promotion.updateForm.mode.placeholder': 'Please enter the Mode!',
  'pages.Promotion.product.required': 'The Product is required',
  'pages.Promotion.updateForm.product.placeholder': 'Please enter the Product!',
  'pages.Promotion.flat.required': 'The Flat is required',
  'pages.Promotion.updateForm.flat.placeholder': 'Please enter the Flat!',
  'pages.Promotion.currency.required': 'The Currency is required',
  'pages.Promotion.updateForm.currency.placeholder': 'Please enter the Currency!',
  'pages.Promotion.percentage.required': 'The Percentage is required',
  'pages.Promotion.updateForm.percentage.placeholder': 'Please enter the Percentage!',
  'pages.Promotion.max_total_usage.required': 'The Max total usage is required',
  'pages.Promotion.updateForm.max_total_usage.placeholder': 'Please enter the Max total usage!',
  'pages.Promotion.start_date.required': 'The Start Date is required',
  'pages.Promotion.updateForm.start_date.placeholder': 'Please enter the Start Date!',
  'pages.Promotion.end_date.required': 'The End Date is required',
  'pages.Promotion.updateForm.end_date.placeholder': 'Please enter the End Date!',
  'pages.Promotion.user_mobile.required': 'The User Mobile is required',
  'pages.Promotion.updateForm.user_mobile.placeholder': 'Please enter the User(s) Mobile!',
  'pages.Promotion.updateForm.tnc.placeholder': 'www.oyika.com/prouctA/tnc',
  'pages.Promotion.start_date': 'Start Date',
  'pages.Promotion.end_date': 'End Date',
  'pages.Promotion.merchant_code': 'Merchant',
  'pages.Promotion.application': 'Application',
  'pages.Promotion.mode': 'Mode',
  'pages.Promotion.orderType': 'Order Type',
  'pages.Promotion.product': 'Product',
  'pages.Promotion.titleOption': 'Action',
  'pages.Promotion.currency': 'Currency',
  'pages.Promotion.order_type': 'Order Type',
  'pages.Promotion.order_frequency': 'Min. Order Frequency',
  'pages.Promotion.edit': 'Edit',
  'pages.Promotion.delete': 'Delete',
  'pages.Promotion.updateForm.title': 'Edit Promotion/Coupoun',
  'pages.Promotion.expire': 'Expired',
  'pages.Promotion.reject': 'Reject',
  'pages.Promotion.approve': 'Approve',
  'pages.Promotion.status': 'Status',
  'pages.Promotion.createdBy': 'Created By',
  'pages.Promotion.offerType': 'Offer Type',
  'pages.Promotion.amount': 'Amount',
  'pages.Product.updateForm.name.placeholder': 'Please enter the Name!',
  'pages.Product.updateForm.description.placeholder': 'Please enter the Description!',
  'pages.Product.name': 'Product Name',
  'pages.Product.packageId': 'Package ID',
  'pages.Product.productCode': 'Product Code (For Promo code)',
  'pages.Product.productDescription': 'Description',
  'pages.Product.action': 'Actions',
  'pages.Product.title': 'Product List: For easy reference to product code when creating promotion',
  'pages.UserList.applicationName': 'Application Name is required',
  'pages.UserList.application.placeholder': 'Please select the Application Name!',
  'pages.UserList.ruleName': 'Role is required!',
  'pages.UserList.role.placeholder': 'Please select the Role!',
  'pages.UserList.email': 'Email is required',
  'pages.UserList.email.placeholder': 'Please enter the Email!',
  'pages.UserList.password': 'Password is required',
  'pages.UserList.password.placeholder': 'Please enter the Password!',
  'pages.UserList.mobile': 'Mobile is required',
  'pages.UserList.mobile.placeholder': 'Please enter the Mobile!',
  'pages.User.updateForm.title': 'Edit User',
  'pages.User.updateForm.order.placeholder': 'Pleae select the Role',
  'pages.searchTable.titlex': 'User Form',
  'pages.searchTable.createForm.newUser': 'New User',
};
