// @ts-ignore
/* eslint-disable */
import { message } from 'antd';
import { request, history } from 'umi';

const accessTokenKey = 'accessToken';

// const baseURL = 'http://localhost:3000/';
const baseURL = 'https://promo-dev.oyika.net/api/';

/** 获取当前的用户 GET /api/currentUser */
export async function currentUser(options?: { [key: string]: any }) {
  const user = JSON.parse(window.localStorage.getItem('user') ?? '{}');
  const groups = user.role;
  const role = groups.includes('Super Admin')
    ? 'admin'
    : groups.includes('Admin')
    ? 'manager'
    : 'user';
  // return request<{
  //   data: API.CurrentUser;
  // }>('/api/currentUser', {
  //   method: 'GET',
  //   ...(options || {skipErrorHandler: true}),
  // });
  return {
    data: {
      name: user ? user.name : '',
      access: role,
    },
  };
}

export async function getHeaders() {
  const expTk: any = window.localStorage.getItem('exptk');
  // refresh token before expire 5 min
  // TODO: unable refresh token
  // if (expTk * 1000 - 5 * 60000 <= Date.now()) {
  //   window.localStorage.removeItem('user');
  //   window.localStorage.removeItem('accessTokenKey');
  //   return history.push('/login');
  // }

  return Promise.resolve({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${
      JSON.parse(window.localStorage.getItem(accessTokenKey) ?? '{}').accessToken
    }`,
  });
}

/** 退出登录接口 POST /api/login/outLogin */
export async function outLogin(options?: { [key: string]: any }) {
  window.localStorage.removeItem(accessTokenKey);
  window.localStorage.removeItem('user');
  window.localStorage.removeItem('emailLogin');
  window.localStorage.removeItem('exptk');

  return { success: true };
}

/** 登录接口 POST /api/login/account */
export async function login(body: API.LoginParams, options?: { [key: string]: any }) {
  const res = await request(`${baseURL}login`, {
    method: 'POST',
    data: body,
  })
    .then((data) => {
      const { token, user } = data;
      const groups = user.role;
      window.localStorage.setItem('user', JSON.stringify(user));
      window.localStorage.setItem(accessTokenKey, JSON.stringify(token));
      window.localStorage.setItem('exptk', token.expiresIn);
      setTimeout(outLogin, token.expiresIn * 1000);
      const role = groups.includes('Super Admin')
        ? 'admin'
        : groups.includes('Admin')
        ? 'manager'
        : 'user';

      return {
        name: user.name,
        status: 'ok',
        type: 'account',
        currentAuthority: role,
      };
    })
    .catch((ex) => console.log('error login', ex));
  return res;
}

export async function register(body: API.LoginParams, options?: { [key: string]: any }) {
  const data = {
    ...body,
    name: body?.email,
    password_confirmation: body?.password,
  };

  return request<any>('/api/signup', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
    ...(options || {}),
  }).then((data) => {
    return { ...data, status: 'ok' };
  });
}

export async function getDashboard(params: {}, options?: { [key: string]: any }) {
  const headers = await getHeaders();
  return request<any>(`${baseURL}dashboard`, {
    method: 'GET',
    headers,
    params,
    ...(options || {}),
  }).then((res) => {
    return res;
  });
}

export async function exportUsage(params: {}, options?: { [key: string]: any }) {
  const headers = await getHeaders();

  return request<any>(`${baseURL}usage/export`, {
    method: 'GET',
    headers,
    params,
    ...(options || {}),
  }).then((res) => res);
}

/** 获取规则列表 GET /api/Promotions */
export async function getPromotions(
  params: {
    current?: number;
    pageSize?: number;
    sort?: string;
    direction?: string;
    code?: string;
    mode?: string;
    product?: string;
    currency?: string;
    start_date?: Date;
    end_date?: Date;
    merchant_code?: string;
  },
  options?: { [key: string]: any },
) {
  const headers = await getHeaders();
  return request<any>(`${baseURL}promos`, {
    method: 'GET',
    headers,
    params,
    ...(options || {}),
    skipErrorHandler: true,
  })
    .then((res) => {
      return {
        data: res.data,
        total: res.total,
        success: true,
        pageSize: res.pageSize,
        current: res.current,
      };
    })
    .catch((error) => {
      message.error(error.data.message ?? error.message);
    });
}

/** 获取规则列表 GET /api/usage */
export async function getUsage(
  params: {
    current?: number;
    pageSize?: number;
    sort?: string;
    direction?: string;
    code?: string;
  },
  options?: { [key: string]: any },
) {
  const headers = await getHeaders();
  return request<any>(`${baseURL}usage`, {
    method: 'GET',
    headers,
    params,
    ...(options || {}),
  }).then((res) => {
    return {
      data: res.data,
      total: res.total,
      success: true,
      pageSize: res.pageSize,
      current: res.current,
    };
  });
}

export async function addProducts(body: API.ProductItem[], options?: { [key: string]: any }) {
  const user: API.UserItem = JSON.parse(window.localStorage.getItem('user') ?? '{}');
  const headers = await getHeaders();
  const url = `${baseURL}products`;
  return request<any>(url, {
    method: 'POST',
    headers,
    data: body,
    ...(options || {}),
  });
}

export async function deleteProduct(body: API.ProductItem, options?: { [key: string]: any }) {
  const { id } = body;
  const headers = await getHeaders();
  return request<any>(`${baseURL}products/${id}`, {
    method: 'PUT',
    headers,
    data: {},
    ...(options || {}),
  });
}

export async function editProduct(body: API.ProductItem, options?: { [key: string]: any }) {
  const { id } = body;
  const headers = await getHeaders();
  return request<any>(`${baseURL}products/${id}`, {
    method: 'PATCH',
    headers,
    data: { ...body },
    ...(options || {}),
  });
}

export async function getProducts(
  params: {
    current?: number;
    pageSize?: number;
    sort?: string;
    direction?: string;
    code?: string;
  },
  options?: { [key: string]: any },
) {
  const headers = await getHeaders();
  return request<any>(`${baseURL}products`, {
    method: 'GET',
    headers,
    params,
    ...(options || {}),
  }).then((res) => {
    return <API.ProductList>{
      data: res.data,
      total: res.total,
      success: true,
      pageSize: res.pageSize,
      current: res.current,
    };
  });
}

/** add workshop /api/Promotions */
export async function addPromotion(body: API.PromotionItem, options?: { [key: string]: any }) {
  const user: API.UserItem = JSON.parse(window.localStorage.getItem('user') ?? '{}');
  const headers = await getHeaders();
  const url = `${baseURL}promos`;
  return request<any>(url, {
    method: 'POST',
    headers,
    data: { ...body, created_by: user?.email },
    ...(options || {}),
  }).then((res) => res.id !== null);
}

/** approve an existing Promotion PATCH /api/Promotion/{id} */
export async function approvePromotion(body: API.PromotionItem, options?: { [key: string]: any }) {
  const { id } = body;
  const headers = await getHeaders();
  return request<any>(`${baseURL}promos/${id}`, {
    method: 'PATCH',
    headers,
    data: { ...body },
    ...(options || {}),
  });
}

export async function signUp(data: API.UserItem) {
  const role = data.role;
  const userInfo = {
    email: data.email,
    name: data.name,
    password: data.password,
    applicationId: data.applicationId,
  };
  if (role === 'Admin') {
    try {
      const headers = await getHeaders();
      return request<any>(`${baseURL}admins`, {
        method: 'POST',
        headers,
        data: userInfo,
        ...{},
      }).then((res) => {
        console.log('sss', res);
        return res.id !== null;
      });
    } catch (error) {
      console.log('error signing up:', error);
    }
  } else if (role === 'User') {
    try {
      const headers = await getHeaders();
      return request<any>(`${baseURL}users`, {
        method: 'POST',
        headers,
        data: userInfo,
        ...{},
      }).then((res) => {
        console.log('sss', res);
        return res.id !== null;
      });
    } catch (error) {
      console.log('error signing up:', error);
    }
  }
}

export async function updateUser(mobile: string, group: string) {
  try {
    const headers = await getHeaders();
    return request<any>(`${baseURL}users`, {
      method: 'PUT',
      headers,
      data: { mobile, group },
      ...{},
    });
  } catch (error) {
    console.log('error confirming sign up', error);
  }
}

/** Update an existing Promotion PUT /api/Promotion/{id} */
export async function updatePromotion(body: API.PromotionItem, options?: { [key: string]: any }) {
  const { id } = body;
  const headers = await getHeaders();
  console.log(typeof body.start_date);
  return request<any>(`${baseURL}promos/${id}`, {
    method: 'PATCH',
    headers,
    data: { ...body },
    ...(options || {}),
  });
}

export async function changeStatusPromotion(
  body: API.PromotionItem,
  options?: { [key: string]: any },
) {
  const { id } = body;
  const headers = await getHeaders();
  return request<any>(`${baseURL}promos/status/${id}`, {
    method: 'PATCH',
    headers,
    data: {
      status: body.status,
    },
    ...(options || {}),
  });
}

export async function getUsers(params: {}, options?: { [key: string]: any }) {
  const headers = await getHeaders();
  return request<any>(`${baseURL}users`, {
    method: 'GET',
    headers,
    params,
    ...(options || {}),
  }).then((res) => {
    return <API.UserList>{
      data: res.data,
      total: res.total,
      success: true,
      pageSize: res.pageSize,
      current: res.current,
    };
  });
}

export async function deleteUser(body: API.UserItem) {
  const headers = await getHeaders();
  return request<any>(`${baseURL}users`, {
    method: 'DELETE',
    headers,
    data: body,
  });
}

export async function generateCode() {
  const headers = await getHeaders();
  return request<any>(`${baseURL}promos/random`, {
    method: 'GET',
    headers,
  }).then((res) => {
    return res;
  });
}

export async function getListApplication(
  params?: { [key: string]: any },
  options?: { [key: string]: any },
) {
  const headers = await getHeaders();
  return request<any>(`${baseURL}applications`, {
    method: 'GET',
    headers,
    params,
  }).then((res) => {
    return {
      success: true,
      data: res?.data,
    };
  });
}

export async function addApplication(payload: API.ApplicationItem) {
  const headers = await getHeaders();
  return request<any>(`${baseURL}applications`, {
    method: 'POST',
    headers,
    data: payload,
  }).then((res) => {
    return {
      success: true,
      data: res?.data,
    };
  });
}

export async function updateApplication(payload: API.ApplicationItem) {
  const headers = await getHeaders();
  const { id, ...body } = payload;

  return request<any>(`${baseURL}applications/${id}`, {
    method: 'PUT',
    headers,
    data: body,
  }).then((res) => {
    return {
      success: true,
      data: res?.data,
    };
  });
}

export async function deleteApplication(id: string) {
  const headers = await getHeaders();

  return request<any>(`${baseURL}applications/${id}`, {
    method: 'DELETE',
    headers,
  }).then((res) => {
    return {
      success: true,
      data: res?.data,
    };
  });
}

export async function resetPassword(payload: {
  id: string;
  newPassword: string;
  confirmNewPassword: string;
}) {
  const headers = await getHeaders();
  const { id, newPassword, confirmNewPassword } = payload;
  return request<any>(`${baseURL}users/${id}/password`, {
    method: 'PUT',
    headers,
    data: {
      newPassword,
      confirmNewPassword,
    },
  }).then((res) => {
    return {
      success: true,
      data: res?.data,
    };
  });
}
