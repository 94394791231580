// @ts-nocheck

import ControlOutlined from '@ant-design/icons/ControlOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import TableOutlined from '@ant-design/icons/TableOutlined';
import DashboardOutlined from '@ant-design/icons/DashboardOutlined'

export default {
  ControlOutlined,
UserOutlined,
TableOutlined,
DashboardOutlined
}
    