/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */
export default function access(initialState: { currentUser?: API.CurrentUser | undefined }) {
  const { currentUser } = initialState ?? {};
  return {
    canAdmin: currentUser && currentUser.access === 'admin',
    canManager: currentUser && currentUser.access === 'manager',
    canAdminOrManager:
      currentUser && (currentUser.access === 'admin' || currentUser.access === 'manager'),
    canUser: currentUser && currentUser.access === 'user',
  };
}
