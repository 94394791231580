export default {
  'application.name': 'Name',
  'application.email': 'Email',
  'application.phoneNumber': 'Phone Number',
  'application.role': 'Role',
  'application.fullName': 'Full Name',
  'application.title': 'Applications List',
  'application.add': 'Create Application',
  'application.update': 'Update Application',
  'application.confirm': 'Confirm',
  'application.name.required': 'Please Enter Application Name',
  'application.phoneNumber.required': 'Please Enter Phone Number',
  'application.firstName.required': 'Please Enter First Name',
  'application.lastName.required': 'Please Enter Last Name',
  'application.password.required': 'Please Enter Password',
  'application.description': 'Description',
  'application.add.success': 'Create Application Successfully!',
  'application.update.success': 'Update Application Successfully!',
  'application.delete.success': 'Delete Application Successfully!',
  'application.delete': 'Delete Application',
};
