// @ts-nocheck

  import ControlOutlined from '@ant-design/icons/es/icons/ControlOutlined';
import UserOutlined from '@ant-design/icons/es/icons/UserOutlined';
import TableOutlined from '@ant-design/icons/es/icons/TableOutlined';
import DashboardOutlined from '@ant-design/icons/es/icons/DashboardOutlined'
  export default {
    ControlOutlined,
UserOutlined,
TableOutlined,
DashboardOutlined
  }