import type { Settings as LayoutSettings } from '@ant-design/pro-layout';
import { PageLoading } from '@ant-design/pro-layout';
import type { RunTimeLayoutConfig } from 'umi';
import { history, Link } from 'umi';
import RightContent from '@/components/RightContent';
import { jwtDecode } from 'jwt-decode';
import { currentUser as queryCurrentUser } from './services/ant-design-pro/api';
import { message } from 'antd';
const isDev = process.env.NODE_ENV === 'development';
const loginPath = '/login';
const homePath = '/promos';
const registerPath = '/changepwd';
const promosPath = '/promos';

/** 获取用户信息比较慢的时候会展示一个 loading */
export const initialStateConfig = {
  loading: <PageLoading />,
};

const isTokenExpired = (tokenValue: string) => {
  if (tokenValue) {
    const expiry = jwtDecode(tokenValue).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  } else {
    return false;
  }
};

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: API.CurrentUser;
  fetchUserInfo?: () => Promise<API.CurrentUser | undefined>;
}> {
  const accessToken = localStorage.getItem('accessToken');
  const fetchUserInfo = async () => {
    try {
      const msg = await queryCurrentUser();
      return msg.data;
    } catch (error) {
      if (
        history === undefined ||
        (history.location.pathname.indexOf(homePath) != -1 && accessToken)
      ) {
        history.push(homePath);
      } else if (history.location.pathname.indexOf(registerPath) != -1) {
        history.push(registerPath);
      } else {
        history.push(promosPath);
      }
    }
    return undefined;
  };
  // 如果是登录页面，不执行
  if (
    accessToken &&
    history.location.pathname.indexOf(loginPath) === -1 &&
    history.location.pathname.indexOf(registerPath) === -1
  ) {
    const currentUser = await fetchUserInfo();
    return {
      currentUser,
      settings: {},
    };
  }
  return {
    settings: {},
    fetchUserInfo,
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState }) => {
  return {
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    footerRender: false,
    onPageChange: () => {
      const { location } = history;
      const token = localStorage.getItem('accessToken');

      if (token && isTokenExpired(token)) {
        message.error('Login Session time out!!');
        history.push(loginPath);
        localStorage.clear();
      }

      if (
        !initialState?.currentUser &&
        location.pathname.indexOf(loginPath) === -1 &&
        !localStorage.getItem('accessToken')
      ) {
        if (location.pathname.indexOf(registerPath) !== -1 && localStorage.getItem('pwdOld')) {
          history.push(registerPath);
        } else {
          history.push(loginPath);
        }
      }

      if (localStorage.getItem('accessToken')) {
        if (location.pathname.indexOf(registerPath) !== -1) {
          history.push(homePath);
        }
      }
    },
    links: [],
    menuHeaderRender: undefined,
    // 自定义 403 页面
    // unAccessible: <div>unAccessible</div>,
    // 增加一个 loading 的状态
    // childrenRender: (children) => {
    //   if (initialState.loading) return <PageLoading />;
    //   return children;
    // },
    ...initialState?.settings,
  };
};
